<template>
  <div class="page">
    <img src="https://douzhuanduokai.oss-cn-beijing.aliyuncs.com/image/appba.png" alt="Banner Image" class="banner-image">
  </div>
</template>

<script>
export default {
  components: { },
  props: {},
  data() {
    return {
      isLoading: true
    };
  },
  watch: {},
  computed: {},
  methods: {
  },
  created() {
  },
  mounted() {},
};
</script>
<style>
.banner-image {
  width: 100%;
  height: auto;
}
.description {
  font-size: 16px;
  line-height: 1.5;
  margin: 20px 0;
  text-align: center;
}
.footer-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-link {
  display: inline-block;
  padding: 10px 30px;
  background-color: #42b983;
  color: #fff;
  font-size: 16px;
  border-radius: 20px;
  text-decoration: none;
}
</style>